<template>
  <div v-if="collaborators.length">
    <h3
      class="mt-5 tg-mobile-header-3 lg:tg-desktop-header-3"
      v-text="$t('advisor')"
    />
    <div
      v-for="collaborator in collaborators"
      :key="collaborator.id"
      :class="[
        'relative my-12 rounded-lg shadow-card px-5 flex flex-col text-center',
        { 'md:mb-20': collaborator.picture }
      ]"
    >
      <div
        v-if="collaborator.picture"
        class="absolute inset-x-0 transform -translate-y-1/2 flex-shrink-0 overflow-hidden"
      >
        <img
          :src="collaborator.picture"
          :alt="$t('photo')"
          class="mx-auto object-cover rounded-full h-14 w-14 md:h-24 md:w-24 bg-white text-center leading-8"
        />
      </div>
      <h3
        :class="[
          'mt-5 tg-mobile-header-3 lg:tg-desktop-header-3 text-brand-primary',
          { 'mt-7 md:mt-12': collaborator.picture }
        ]"
      >
        {{ collaborator.display_name }}
      </h3>
      <div class="my-4 tg-desktop-body">
        <h5 class="font-semibold">
          {{ collaborator.office.name }}
        </h5>
        <span>
          {{ collaborator.office.street }} {{ collaborator.office.box }}
        </span>
        <br />
        <span>
          {{ collaborator.office.zip_code }} {{ collaborator.office.city }}
        </span>
      </div>
      <div class="mb-5 flex flex-wrap justify-center">
        <a
          v-if="collaborator.mobile"
          :href="'tel:' + collaborator.mobile"
          target="_blank"
          class="m-2 px-4 rounded-full tg-mobile-body-small-bold lg:tg-desktop-body-bold text-brand-primary border border-brand-primary hover:opacity-60"
        >
          <i class="far fa-phone" />
          {{ collaborator.mobile }}
        </a>
        <a
          v-if="collaborator.email"
          :href="'mailto:' + collaborator.email"
          target="_blank"
          class="m-2 px-4 rounded-full tg-mobile-body-small-bold lg:tg-desktop-body-bold text-brand-primary border border-brand-primary hover:opacity-60"
        >
          <i class="far fa-envelope" />
          {{ $t('mail_me') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyCollaborators',
  props: {
    collaborators: {
      type: Array,
      required: true
    }
  }
};
</script>

<template>
  <div v-if="longText">
    <p v-text="textToShow" />
    <div
      v-if="readMoreNeeded"
      class="w-full h-full text-center cursor-pointer"
      @click="toggleTextLength"
    >
      <span class="tg-desktop-link link underline">
        <i
          :class="['fal', readMoreActivated ? 'fa-arrow-up' : 'fa-arrow-down']"
        />
        {{ readMoreActivated ? $t('show_less') : $t('show_more') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    longText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      readMoreActivated: false
    };
  },
  computed: {
    readMoreNeeded() {
      return this.longText.length > 200 ? true : false;
    },
    textToShow() {
      return this.readMoreActivated
        ? this.longText
        : this.longText.slice(0, 200);
    }
  },
  methods: {
    hideText() {
      this.readMoreActivated = false;
    },
    showText() {
      this.readMoreActivated = true;
    },
    toggleTextLength() {
      if (this.readMoreActivated) {
        this.hideText();
      } else {
        this.showText();
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="rounded-lg shadow-card divide-y-0.5 divide-gray-e9">
      <div
        v-for="(detail, index) in propertyDetails"
        :key="index"
        class="grid grid-cols-2 divide-x-0.5 divide-gray-e9"
      >
        <span
          class="px-5 py-2.5 lg:py-5 tg-mobile-body-small-bold lg:tg-desktop-body-bold"
          v-text="detail.title"
        />
        <span class="px-5 py-2.5 lg:py-5">
          <i
            v-if="detail.boolean"
            :class="['fal', detail.value ? 'fa-check' : 'fa-times']"
          />
          <template v-else-if="detail.value">
            <span v-text="detail.value" />
            <span v-if="detail.unit" v-text="detail.unit" />
            <sup v-if="detail.sup" v-text="2" />
          </template>
          <template v-else>-</template>
        </span>
      </div>
    </div>

    <h3 class="tg-mobile-header-3 lg:tg-desktop-header-3 my-5">
      {{ $t('description') }}
    </h3>
    <ReadMore :long-text="propertyDescription" />

    <PropertyCollaborators
      v-if="property.collaborators"
      :collaborators="property.collaborators"
    />
  </div>
</template>

<script>
import ReadMore from '@/components/ReadMore';
import PropertyCollaborators from '@/components/PropertyCollaborators';
import { mapState } from 'vuex';
import { currency } from '@/helpers';

export default {
  name: 'PropertyDetail',
  components: {
    ReadMore,
    PropertyCollaborators
  },
  computed: {
    ...mapState('property', ['property']),

    propertyDescription() {
      let descriptionObj = this.property?.descriptions?.find(
        description => description.language === this.$i18n.locale
      );
      return descriptionObj?.description
        ? descriptionObj.description
        : this.$t('no_description_available');
    },

    propertyDetails() {
      let details = [];
      if (this.property) {
        details = [
          {
            title: 'Type',
            value: this.property.type
              ? this.property.type['name_' + this.$i18n.locale]
              : ''
          },
          {
            title: this.$t('construction_year'),
            value: this.property.build_year
          },
          {
            title: this.$t('buildings'),
            value: this.property.building_type_display
          },
          {
            title: this.$t('ground_surface'),
            value: this.property.surface_plot,
            unit: 'm',
            sup: true
          },
          {
            title: 'EPC',
            value: this.property.epc_kwhm,
            unit: 'Kwh/m',
            sup: true
          },
          {
            title: this.$t('bedrooms'),
            value: this.property.bedrooms
          },
          {
            title: this.$t('bathrooms'),
            value: this.property.bathrooms
          },
          {
            title: this.$t('garden'),
            value: this.property.surface_garden,
            unit: 'm',
            sup: true
          },
          {
            title: this.$t('terrace'),
            value: this.property.surface_terras,
            unit: 'm',
            sup: true
          },
          {
            title: this.$t('living_area'),
            value: this.property.surface_livable,
            unit: 'm',
            sup: true
          },
          {
            title: 'KI',
            value: currency(this.property.cadastral_income)
          }
        ];
      }
      return details;
    }
  }
};
</script>
